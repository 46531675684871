import React from "react";
import { FullWidth } from "../fullWidth/FullWidth";
import "./Services.scss";

export const Item = ({ title, content }) => {
  return (
    <div className="services--grid-item">
      <h3 className="services--grid-item-title">{title}</h3>
      <div className="services--grid-item-content">{content}</div>
    </div>
  );
};

export const Services = () => {
  return (
    <FullWidth id="services" innerClassName="services--root" tabIndex={-1}>
      <div className="services--inner inner-column">
        <h2>Consulting Services</h2>
        <div className="services--summary">
          We support the capacity of community-based organizations through
          management tools, coaching, and skill development, as they develop
          resilient networks and boldly improve outcomes for their communities.
        </div>
      </div>
      <div className="services--grid">
        <Item
          title="Leadership Coaching"
          content={
            <>
              We coach leaders as they navigate the challenging waters of
              nonprofit leadership and build resilient communities.
            </>
          }
        />
        <Item
          title="Strategic Thinking"
          content={
            <>
              We do the facilitating as you create tools for thinking
              strategically - articulating your mission, vision, values,
              strategic priorities, and actions into a strategic plan.
            </>
          }
        />
        <Item
          title="Organizational Development"
          content={
            <>
              We develop organizational tools that improve your impact - enhance
              data and evaluation systems, broker partnerships, and facilitate
              leadership development.
            </>
          }
        />
        <Item
          title="IT Support"
          content={
            <>
              From hardware and program installation, to troubleshooting your IT
              challenges and finding solutions, our team can help you improve
              and upgrade your technology and your website.
            </>
          }
        />
        <Item
          title="Facilitation"
          content={
            <>
              We use the Art of Hosting and Gracious Space methods to facilitate
              conversations that provide the structure for meaningful
              conversations and participatory leadership.
            </>
          }
        />
        <Item
          title="Fundraising"
          content={
            <>
              We help you raise funds - create a fundraising plan, launch an
              annual campaign, and write grant proposals.
            </>
          }
        />
        <Item
          title="Race &amp; Social Justice"
          content={
            <>
              Our team recognizes that the organizations we work with already
              have the solutions to the issues that affect their communities. We
              coach leaders to lean into their cultural practices to build
              resilience and change systems.
            </>
          }
        />
        <Item
          title="Communication"
          content={
            <>
              We can help you write proposals, create a strategic communications
              plan, and upscale your social media messages.
            </>
          }
        />
      </div>
    </FullWidth>
  );
};
