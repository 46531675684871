import * as React from "react";
import { Layout } from "../components/layout/Layout";
import { CallToAction } from "../components/index/CallToAction";
import { Values } from "../components/index/Values";
import { Mission } from "../components/index/Mission";
import { Hr } from "../components/hr/Hr";
import { Services } from "../components/index/Services";
import { HowItWorks } from "../components/index/HowItWorks";
import { Partners } from "../components/index/Partners";
import { ContactForm } from "../components/index/ContactForm";
import { Testimonials } from "../components/index/Testimonials";
import { LandAcknowledgment } from "../components/index/LandAcknowledgment";

const Index = () => {
  return (
    <Layout>
      <CallToAction />
      <Values />
      <Mission />
      <Hr />
      <Services />
      <HowItWorks />
      <Hr />
      <Partners />
      <Testimonials />
      <ContactForm />
      <Hr />
      <LandAcknowledgment />
    </Layout>
  );
};

export default Index;
