import React from "react";
import { ContactButton } from "../contactButton/ContactButton";
import { FullWidth } from "../fullWidth/FullWidth";

import "./HowItWorks.scss";

const items = [
  {
    title: "We listen.",
    content: (
      <>
        We follow your lead to understand community strengths and gifts. Then
        develop responsive support that build capacity and accelerates your
        impact for children, youth, and families.
      </>
    ),
  },
  {
    title: "We view and act through a racial equity lens.",
    content: (
      <>
        We use a racial equity analysis lens in all of our interactions, from
        writing to coaching. We boldly work to be anti-racist.
      </>
    ),
  },
  {
    title: "We collaborate and adjust.",
    content: (
      <>
        We center the vital and enduring cultures of organizations bringing an
        inquisitive and collaborative mindset to our work. We provide tools and
        ideas, help to identify strategic levers, and solicit feedback
        throughout the relationship, making constant adjustments to better serve
        our clients.
      </>
    ),
  },
];

export const HowItWorks = () => {
  return (
    <FullWidth>
      <div className="how-it-works--root">
        <h2>How We Work</h2>
        <div className="how-it-works--explanation">
          {items.map(({ title, content }) => (
            <p>
              <strong>{title}</strong> {content}
            </p>
          ))}
        </div>

        <ContactButton />
      </div>
    </FullWidth>
  );
};
