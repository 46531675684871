import React, { useState } from "react";

import "./ContactForm.scss";

export const ContactForm = () => {
  const [mailTo, setMailTo] = useState(
    "mailto:RHL@@@Con@@@sul@@@tingteam@gmai@@@l.com"
  );
  return (
    <div className="contact-form--root inner-column" id="contact">
      <h2>Let's Get Started!</h2>

      <div className="contact-form--secondary-text">
        Contact us at for your free consultation. CLick the following button,
        Or, send an email to RHLConsultingteam (at) gm
        <span style={{ display: "none" }}>fudge the DOM</span>
        ail<span>.</span>c<span>o</span>m
      </div>

      <a
        href={mailTo}
        onMouseEnter={() => setMailTo(mailTo.replaceAll("@@@", ""))}
        type="button"
        className="contact-form--submit"
      >
        Click here to send an email
      </a>
    </div>
  );
};
