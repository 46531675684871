import cx from "classnames";
import React, { useState } from "react";
import { FullWidth } from "../fullWidth/FullWidth";

import "./Testimonials.scss";

const list = [
  {
    from: "Kyle Schierbeck, Board President, Unkitawa",
    text: `Unkitawa is a Native led CBO non profit, as such it has been a mutually beneficial
      relation working with Roxanne and RHL Consulting. She brings to the table a level of
      understanding that combines the business acumen of the dominant society and the value
      system of the Native and Indigenous cultures. This combination allows Roxanne to assist
      BIPOC organizations from a unique and helpful perspective. To be able to translate
      between the business side and the traditional indigenous perspective has helped Unkitawa
      be more successful in our efforts securing funds to do the work. Our working
      relationship with Roxanne has been positive and we would and have recommended her to
      other native organizations.`,
  },
  {
    from: "Lisa Greenwald, CEO, Kindering",
    text: `
      Working with Roxanne has been transformative for both myself and the organization I lead.
      The combination of her deep knowledge of race equity and social justice paired with her
      understanding of executive leadership and non-profit management were just what we needed
      in order to advance equity more effectively. Roxanne is full of ideas and tools, and thinks
      quickly on her feet. She has a knack for showing compassion while quickly getting to action
      with results that always include centering marginalized voices and advancing equity.
    `,
  },
  {
    from: "Stephanie Ung, Khmer Association",
    text: `Roxanne and her team have been a tremendous help in facilitating the grant writing and
      fundraising process for our non-profit. They supported us to develop meaningful tools that
      align with our mission, vision, values, and strategic planning. We appreciate the enthusiasm
      the team brings for learning about our work, and the openness in our brainstorming phase. It
      really feels like they care about us learning throughout the process and feeling empowered
      to put our own spin on the frameworks and guidance they do provide.`,
  },
  {
    from: "Richard Maltby, SeaMar",
    text: `I know I've thanked you a thousand times, but none of this is happening without your
      help.  Especially after the detour the entire project took with less than a week left
      before the deadline.  You helped me above and beyond what I could have ever hoped.  Whether
      or not we earn this RFP, I learned a lot working with you and enjoyed the experience. `,
  },
];

export const Testimonials = () => {
  const [quoteIdx, setQuoteIdx] = useState(0);

  return (
    <FullWidth
      outerClassName="testimonials--root"
      innerClassName="testimonials--root-inner"
    >
      <h2 className="testimonials--header">Testimonials</h2>
      <div className="slider--root">
        <button
          className="slider--left-arrow"
          onClick={() => {
            let newIdx = quoteIdx - 1;
            if (newIdx === -1) {
              newIdx = list.length - 1;
            }
            setQuoteIdx(newIdx);
          }}
        >
          ❮
        </button>
        <div className="slider--quote-root">
          <div className="slider--quote">“</div>
          <div>
            <div className="slider--quote-text">{list[quoteIdx].text}</div>
          </div>
          <div className="slider--quote">”</div>
        </div>
        <button
          className="slider--right-arrow"
          onClick={() => {
            let newIdx = quoteIdx + 1;
            if (newIdx === list.length) {
              newIdx = 0;
            }
            setQuoteIdx(newIdx);
          }}
        >
          ❯
        </button>
      </div>
      <div className="slider--quote-from">-{list[quoteIdx].from}</div>
      <div className="slider--dots">
        {list.map((_element, i) => (
          <div
            className={cx("slider--dot", {
              "slider--dot--active": i === quoteIdx,
            })}
          />
        ))}
      </div>
    </FullWidth>
  );
};
