import cx from "classnames";
import { Link } from "gatsby";
import React from "react";
import "./ContactButton.scss";

export const ContactButton = ({ className, ...rest }) => {
  return (
    <Link
      className={cx("contact-button--root", className)}
      to="/#contact"
      {...rest}
    >
      Contact Us!
    </Link>
  );
};
