import React from "react";

import "./LandAcknowledgment.scss";

export const LandAcknowledgment = () => {
  return (
    <div className="land-ack--root inner-column">
      <h2>Land Acknowledgement</h2>

      <div>
        We live and work on the traditional home lands of the sx̌ʷəbabš Coast
        Salish Native Peoples - the Puyallup, Duwamish, Nisqually, Squaxin
        Island, Suquamish, and Muckleshoot Peoples – who have stewarded these
        lands since the beginning of time and continue to do so today. We commit
        to honor and uplift the voices, experiences, histories, and leadership
        of Indigenous Peoples.
      </div>
    </div>
  );
};
