import React from "react";
import "./Hr.scss";

export const Hr = () => {
  return (
    <div>
      <div className="hr" aria-hidden />
    </div>
  );
};
