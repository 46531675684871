import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { ContactButton } from "../contactButton/ContactButton";
import { FullWidth } from "../fullWidth/FullWidth";

import "./CallToAction.scss";

export const CallToAction = () => {
  return (
    <FullWidth
      outerClassName="call-to-action--container"
      innerClassName="call-to-action--inner-container"
    >
      <div className="call-to-action--root">
        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/3_girls_pic.png"
            alt="Three young girls hugging and smiling"
            layout="fullWidth"
            objectFit="cover"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/family_pic.png"
            alt="A family photo of 13 people taken on the steps of a house"
            layout="fullWidth"
            objectFit="cover"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/rally_pic.png"
            alt="many women at a rally showing support for refugees"
            layout="fullWidth"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/mother_and_daughter_pic.png"
            alt="a mother and daughter hugging tightly and smiling"
            layout="fullWidth"
          />
        </div>

        <div className="call-to-action--tile call-to-action--tile--summary">
          <div>
            We strive to help you build a stronger, more equitable organization.
          </div>
          <ContactButton className="call-to-action--contact-button" />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/teenage_girl_pic.png"
            alt="a teenage girl smiling at the camera"
            layout="fullWidth"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/Everyone_is_welcome_pic.png"
            alt="A sign saying everyone is welcome"
            layout="fullWidth"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/woman_holding_woven_blanket_pic.png"
            alt="A woman smiling and showing off a woven blanket"
            layout="fullWidth"
          />
        </div>

        <div className="call-to-action--tile">
          <StaticImage
            src="../../images/grandpa_and_grandson_pic.png"
            alt="A grandpa and grandson laughing with each other"
            layout="fullWidth"
          />
        </div>
      </div>
    </FullWidth>
  );
};
