import React from "react";
import { FullWidth } from "../fullWidth/FullWidth";
import "./Mission.scss";

export const Mission = () => {
  return (
    <FullWidth
      id="mission"
      outerClassName="mission--root"
      innerClassName="mission--inner"
      tabIndex={-1}
    >
      <div className="inner-column">
        <h2 className="mission--header">Our Mission</h2>
        <div className="mission--text">
          RHL Consulting provides leadership coaching and strategic design for
          communities building a just world.
        </div>
      </div>
    </FullWidth>
  );
};
